a {
    color: $basic-color;
    &:hover {
        color: $hover-color;
        text-decoration: none;
    }
}

body {
    font-family: Helvetica;
}

.btn-link {
    color: $basic-color;
    &:hover {
        color: $hover-color;
        text-decoration: none;
    }
}

#toasts-fixed {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999999;
}

table#invoices-table th.icon {
    cursor: pointer;
}

.depositChapterSelectNav a.nav-link.active {
    background-color: $hover-color !important;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: $hover-color;
}

div#app nav.navbar {
    background-color: $hover-color !important;
}

.purple-background {
    background-color: $basic-color !important;
}

#content {
    margin: 7rem 2rem 10rem;
    padding: 4rem 4rem 7rem;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0.8rem 1.8rem 0 rgba(31,21,80,0.15);
    border: 1px solid $hover-color;
}

#progress-bar {
    &.success {
        color: green;
    }
    padding: 20px;
    text-align: center;
    font-size: 15px;
}

#invoices-table input {
    width: 110px;
    margin: 0 !important;
}

#entity-siret {
    font-size: 18px;
}

.navbar.navbar-light .navbar-nav .nav-link {
    color: #000000 !important;
}

.bg-login {
    background-color: #E9EEF2;
}

body.c-app {
    background-color: $white;
}

.authLogoImg {
    min-height: 220px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image:url('images/logo-akto-white.png');
}

tr.invoicerow.clicked, tr.invoicedetails.clicked {
    background-color: rgba(104, 239, 173, .5) !important;
}

/*
 * DEPOSIT
 */

 .depositDisclaimer {
    color:rgb(109, 109, 109);
    font-size: 0.8rem;
    background-color: #eeeeee;
 }

 .previewMissingAnnexAlert {
    font-size: 0.6rem;
    border: 1px solid #761b18 !important;
    width: max-content !important;
 }

.alert.isImage {
     font-size: 0.6rem;
 }

#invoices-table td input{
    display: none;
}

#invoices-table td.is-invalid {
    //background: #ffc5c5;
}

#invoices-table td.editing {
    input {
        display: block;
    }
    a {
        display: none;
    }
    button {
        display: none;
    }
}


.fileMaxSizeAlert {
    display: none;
}

 .dropzone-previews .filePreview {
     border-bottom: 2px solid $basic-color !important;
 }

.filePreview .previewIcon {
    font-size: 3.0rem;
    color: #C51F1A;
    -ms-flex: 0 0 65px;
    flex: 0 0 65px;
}

.filePreview .filePreviewName a {
    color: #000000;
    font-weight: bold;
}

.annexDropZone {
    border: 1px solid grey;
    background-color: #fef1ff;
}

.annexDropZone .dz-message {
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    padding-top: 10px;
}

.annexPreview a {
    color:#000000;
}

#entitiesAccordion:hover .card-body .repositorySelector {
    cursor: pointer;
}

div#fileDeposit .dz-message {
    border: 1px solid grey;
    padding: 50px;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    background-image: url('images/combined-shape.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

div#fileDeposit .dz-message:hover, .annexDropZone .dz-message:hover {
    cursor: pointer;
}

.annexDropZone {
    cursor: pointer;
}

.depositPreviewContainer {
    padding-top: 20px;
}

.dropzone-previews div.file, .advanced-filters {
    background-color: #f8f8f8;
    border: 2px solid #cccccc;
}

.dropzone-previews div.file div.col-10 {
    padding-top: 4px;
}

.dropzone-previews div.file:hover {
    border: 2px solid $links;
}

.fileTagList {
    padding-left: 20px;
}

.fileTagList button.tag {
    background-color:#666666;
    color:#FFFFFF;
    padding: 0 11px;
    cursor: pointer;
    border-radius: 20px;
    height: 24px;
    margin-top: 6px;
    margin-right: 5px;
}

.fileTagList button.tag input {
    height:20px;
    background-color:#666666;
    color:#FFFFFF;
    width: 70px;
    border: none;
}

div.formValidation {
    padding-top: 10px;
}

form#depositdropzone {
    width: 100%;
}

span.mandatory {
    color:red;
}

/*
 * MODAL
 */

div#fullScreenModal {
    position: fixed;
    z-index: 9000;
    background-color: #ffffff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
}

.big-border-purple{
    border: 1px solid;
    border-color: $basic-color;
    border-radius: 0px;
}

.validation-btn{
    border: 1px solid;
    border-color: $basic-color;
    border-radius: 0px;
}

.validation-btn:hover:enabled{
    background-color: $basic-color;
    color: white;
}

.validation-btn:hover:disabled{
    cursor: not-allowed;
}

.validation-btn:disabled{
    background-color: lightgrey;
}

.revert-validation-btn{
    color: white;
    border: 1px solid;
    background-color: $basic-color;
    border-color: $basic-color;
    border-radius: 0px;
}

.revert-validation-btn:hover{
    background-color: white;
    color: $basic-color;
}

.deletion-btn {
    border-color: #d0211c;
    background-color: white;
    color: #d0211c;
}

.deletion-btn:hover {
    color: white;
    border-color: #d0211c;
    background-color: #d0211c;
}

.border-purple{
    border: 1px solid;
    border-color: $basic-color;
    border-radius: 0px;
}

.col-05 {
    flex: 0 0 4.1666666667%;
    max-width: 4.1666666667%;
}

.col-75 {
        flex: 0 0 62.5%;
        max-width: 62.5%;
}

.nav-white {
    color: white !important;
}

.nav-white:hover {
    color: #68efad !important;
}

.tooltip-wrapper {
    display: block;
}

.tooltip-wrapper .btn[disabled] {
    pointer-events: none;
}

.switch-round-button {
    @media (min-width: 1000px) {
        border-radius: 100px !important;
        height: 130px;
        width: 130px;
        font-size: 1.2em;
    }
    @media (min-width: 900px) and (max-width: 999px) {
        border-radius: 100px !important;
        height: 100px;
        width: 100px;
        font-size: 1em;
    }
    @media (max-width: 899px) {
        border-radius: 100px !important;
        height: 80px;
        width: 80px;
        font-size: .8em;
    }
}

.nav-link.disabled{
    background-color: darkgrey !important;
}

.icon-pdf {
    background-image: url('images/pdf_red.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 20px;
    height: 40px;
}

.overlapping-middle {
    transform: translate(-50%, -50%);
    top:0;
    left:50%;
    position:absolute;
}

.purple-rounded {
    background-color: $hover-color;
    border-radius: 4px
}

.deep-purple-rounded {
    background-color: $body-bg;
    border-radius: 4px
}

.pretty-toast-container {
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 9999
}

.table thead th {
    vertical-align: middle;
}

.invoicerow .js-toggle-dossier[aria-expanded=true] .fa-chevron-down {
    transform: rotate(180deg);
}

.btn-outline-primary {
    color: $basic-color;
    border-color: $basic-color;
}

.btn-outline-primary:hover {
  color: white;
  background-color: $basic-color;
  border-color: white;
}

.w-31 {
    width: 31% !important;
}
.w-33 {
    width: 33% !important;
}
.w-40 {
    width: 40% !important;
}

.save-btn{
    border: 1px solid;
    background-color: $basic-color;
    border-color: $basic-color;
    border-radius: 0px;
    color: white;
}

.save-btn:hover:enabled{
    background-color: white;
}

.save-btn:hover:disabled{
    cursor: not-allowed;
}

.save-btn:disabled{
    background-color: lightgrey;
}

.cancel-btn{
    border: 1px solid;
    border-color: $basic-color;
    border-radius: 0px;
}

.cancel-btn:hover:enabled{
    background-color: $basic-color;
    color: white;
}

.cancel-btn:hover:disabled{
    cursor: not-allowed;
}

.cancel-btn:disabled{
    background-color: lightgrey;
}

.rib-deposit {
    padding: 30px 14px 30px 14px !important;
}

.file-viewer {
    max-height: 600px;
    overflow: auto;
}




/* SALESFORCE NAVBAR */
.navbar-custom {
    min-height: 90px;
  }

.nav-item-custom {
  white-space: nowrap;
}

#two {
    align-self: flex-start;
}