.split, .gutter.gutter-horizontal {
    float: left;
}
.gutter.gutter-horizontal {
    padding: 0px 3px;
    background: rgb(170,170,170);
    background: linear-gradient(90deg, #aaaaaa 44%, #585858 44%, #585858 50%, #585858 56%, #aaaaaa 56%);
    cursor: ew-resize;
    &:hover {
        background: rgb(170,170,170);
        background: linear-gradient(90deg, rgba(170,170,170,1) 44%, rgba(0,0,0,1) 44%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 56%, rgba(170,170,170,1) 56%);
    }
}
